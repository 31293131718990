.ant-table-thead > tr > th {
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
  font-size: 0.875rem;
}
td.ant-table-cell {
  font-size: 0.875rem;
}
.ant-table-content {
  min-height: 40vh;
}
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1bc5bd;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1da7a0;
}
th.MuiTableCell-root.MuiTableCell-head.table-custom-header.MuiTableCell-alignCenter {
  min-width: 170px;
}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation1.MuiPaper-rounded {
  max-height: 35rem;
}
.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  margin-left: 8px;
  margin-right: 8px;
}
.MuiPaper-root.MuiExpansionPanel-root.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded{
  background: rgb(27 197 189);
}
th.ant-table-cell {
  text-transform: capitalize;
}
.input-margin{
  margin-left: 8px;
    margin-right: -8px;
    margin-top: 1%;
}
#Info{
  min-width: 40px !important;
}